import Vue from 'vue'

const components = {
  NuxtDynamic: () => import('../..\\node_modules\\@blokwise\\dynamic\\src\\components\\Dynamic.vue' /* webpackChunkName: "components/nuxt-dynamic" */).then(c => c.default || c),
  Logo: () => import('../..\\components\\Logo.vue' /* webpackChunkName: "components/logo" */).then(c => c.default || c),
  VuetifyLogo: () => import('../..\\components\\VuetifyLogo.vue' /* webpackChunkName: "components/vuetify-logo" */).then(c => c.default || c),
  AdminSettingsAdmColumnsAutoComplete: () => import('../..\\components\\admin-settings\\adm-columns-auto-complete.vue' /* webpackChunkName: "components/admin-settings-adm-columns-auto-complete" */).then(c => c.default || c),
  AdminSettingsGlobalSettings: () => import('../..\\components\\admin-settings\\global-settings.vue' /* webpackChunkName: "components/admin-settings-global-settings" */).then(c => c.default || c),
  AdminSettingsModules: () => import('../..\\components\\admin-settings\\modules.vue' /* webpackChunkName: "components/admin-settings-modules" */).then(c => c.default || c),
  CmsComponentAdd: () => import('../..\\components\\cms\\component-add.vue' /* webpackChunkName: "components/cms-component-add" */).then(c => c.default || c),
  CmsComponentFieldEditDialog: () => import('../..\\components\\cms\\component-field-edit-dialog.vue' /* webpackChunkName: "components/cms-component-field-edit-dialog" */).then(c => c.default || c),
  CmsComponentFilterButton: () => import('../..\\components\\cms\\component-filter-button.vue' /* webpackChunkName: "components/cms-component-filter-button" */).then(c => c.default || c),
  CmsComponentTable: () => import('../..\\components\\cms\\component-table.vue' /* webpackChunkName: "components/cms-component-table" */).then(c => c.default || c),
  CmsContentAdd: () => import('../..\\components\\cms\\content-add.vue' /* webpackChunkName: "components/cms-content-add" */).then(c => c.default || c),
  CmsContentField: () => import('../..\\components\\cms\\content-field.vue' /* webpackChunkName: "components/cms-content-field" */).then(c => c.default || c),
  CmsContentsTable: () => import('../..\\components\\cms\\contents-table.vue' /* webpackChunkName: "components/cms-contents-table" */).then(c => c.default || c),
  CmsExpandingFilterButton: () => import('../..\\components\\cms\\expanding-filter-button.vue' /* webpackChunkName: "components/cms-expanding-filter-button" */).then(c => c.default || c),
  CmsFileInfo: () => import('../..\\components\\cms\\file-info.vue' /* webpackChunkName: "components/cms-file-info" */).then(c => c.default || c),
  CmsFolderAdd: () => import('../..\\components\\cms\\folder-add.vue' /* webpackChunkName: "components/cms-folder-add" */).then(c => c.default || c),
  CmsImageCropper: () => import('../..\\components\\cms\\image-cropper.vue' /* webpackChunkName: "components/cms-image-cropper" */).then(c => c.default || c),
  CmsSectionField: () => import('../..\\components\\cms\\section-field.vue' /* webpackChunkName: "components/cms-section-field" */).then(c => c.default || c),
  CatalogInventoryLocationDialog: () => import('../..\\components\\catalog\\inventory-location-dialog.vue' /* webpackChunkName: "components/catalog-inventory-location-dialog" */).then(c => c.default || c),
  CatalogItemListComponent: () => import('../..\\components\\catalog\\item-list-component.vue' /* webpackChunkName: "components/catalog-item-list-component" */).then(c => c.default || c),
  CatalogProductCard: () => import('../..\\components\\catalog\\product-card.vue' /* webpackChunkName: "components/catalog-product-card" */).then(c => c.default || c),
  CatalogProductDetailDialog: () => import('../..\\components\\catalog\\product-detail-dialog.vue' /* webpackChunkName: "components/catalog-product-detail-dialog" */).then(c => c.default || c),
  CatalogProductListComponent: () => import('../..\\components\\catalog\\product-list-component.vue' /* webpackChunkName: "components/catalog-product-list-component" */).then(c => c.default || c),
  CatalogWishlist: () => import('../..\\components\\catalog\\wishlist.vue' /* webpackChunkName: "components/catalog-wishlist" */).then(c => c.default || c),
  CommonAlertDialog: () => import('../..\\components\\common\\alert-dialog.vue' /* webpackChunkName: "components/common-alert-dialog" */).then(c => c.default || c),
  CommonAttributeDialog: () => import('../..\\components\\common\\attribute-dialog.vue' /* webpackChunkName: "components/common-attribute-dialog" */).then(c => c.default || c),
  CommonBreadcrumbs: () => import('../..\\components\\common\\breadcrumbs.vue' /* webpackChunkName: "components/common-breadcrumbs" */).then(c => c.default || c),
  CommonCameraImageCapture: () => import('../..\\components\\common\\camera-image-capture.vue' /* webpackChunkName: "components/common-camera-image-capture" */).then(c => c.default || c),
  CommonColumnUserSettingDialog: () => import('../..\\components\\common\\column-user-setting-dialog.vue' /* webpackChunkName: "components/common-column-user-setting-dialog" */).then(c => c.default || c),
  CommonConfirm: () => import('../..\\components\\common\\confirm.vue' /* webpackChunkName: "components/common-confirm" */).then(c => c.default || c),
  CommonCurrencyInput: () => import('../..\\components\\common\\currency-input.vue' /* webpackChunkName: "components/common-currency-input" */).then(c => c.default || c),
  CommonCustomerButtons: () => import('../..\\components\\common\\customer-buttons.vue' /* webpackChunkName: "components/common-customer-buttons" */).then(c => c.default || c),
  CommonExpandingFilterButton: () => import('../..\\components\\common\\expanding-filter-button.vue' /* webpackChunkName: "components/common-expanding-filter-button" */).then(c => c.default || c),
  CommonFilterScanDialog: () => import('../..\\components\\common\\filter-scan-dialog.vue' /* webpackChunkName: "components/common-filter-scan-dialog" */).then(c => c.default || c),
  CommonForgotPasswordDialog: () => import('../..\\components\\common\\forgot-password-dialog.vue' /* webpackChunkName: "components/common-forgot-password-dialog" */).then(c => c.default || c),
  CommonFormInput: () => import('../..\\components\\common\\form-input.vue' /* webpackChunkName: "components/common-form-input" */).then(c => c.default || c),
  CommonImageCard: () => import('../..\\components\\common\\image-card.vue' /* webpackChunkName: "components/common-image-card" */).then(c => c.default || c),
  CommonImageDialog: () => import('../..\\components\\common\\image-dialog.vue' /* webpackChunkName: "components/common-image-dialog" */).then(c => c.default || c),
  CommonImageViewerDialog: () => import('../..\\components\\common\\image-viewer-dialog.vue' /* webpackChunkName: "components/common-image-viewer-dialog" */).then(c => c.default || c),
  CommonLeftMenu: () => import('../..\\components\\common\\left-menu.vue' /* webpackChunkName: "components/common-left-menu" */).then(c => c.default || c),
  CommonLoginCard: () => import('../..\\components\\common\\login-card.vue' /* webpackChunkName: "components/common-login-card" */).then(c => c.default || c),
  CommonNavigationBarsLogin: () => import('../..\\components\\common\\navigation-bars-login.vue' /* webpackChunkName: "components/common-navigation-bars-login" */).then(c => c.default || c),
  CommonNavigationBars: () => import('../..\\components\\common\\navigation-bars.vue' /* webpackChunkName: "components/common-navigation-bars" */).then(c => c.default || c),
  CommonNewImageDialog: () => import('../..\\components\\common\\new-image-dialog.vue' /* webpackChunkName: "components/common-new-image-dialog" */).then(c => c.default || c),
  CommonPageTitleSection: () => import('../..\\components\\common\\page-title-section.vue' /* webpackChunkName: "components/common-page-title-section" */).then(c => c.default || c),
  CommonPaymentCardHeader: () => import('../..\\components\\common\\payment-card-header.vue' /* webpackChunkName: "components/common-payment-card-header" */).then(c => c.default || c),
  CommonPaymentCard: () => import('../..\\components\\common\\payment-card.vue' /* webpackChunkName: "components/common-payment-card" */).then(c => c.default || c),
  CommonPaymentDialog: () => import('../..\\components\\common\\payment-dialog.vue' /* webpackChunkName: "components/common-payment-dialog" */).then(c => c.default || c),
  CommonPaymentForm: () => import('../..\\components\\common\\payment-form.vue' /* webpackChunkName: "components/common-payment-form" */).then(c => c.default || c),
  CommonPaymentMethodButtons: () => import('../..\\components\\common\\payment-method-buttons.vue' /* webpackChunkName: "components/common-payment-method-buttons" */).then(c => c.default || c),
  CommonPinLoginCard: () => import('../..\\components\\common\\pin-login-card.vue' /* webpackChunkName: "components/common-pin-login-card" */).then(c => c.default || c),
  CommonPinLoginKeyboard: () => import('../..\\components\\common\\pin-login-keyboard.vue' /* webpackChunkName: "components/common-pin-login-keyboard" */).then(c => c.default || c),
  CommonQuantityInput: () => import('../..\\components\\common\\quantity-input.vue' /* webpackChunkName: "components/common-quantity-input" */).then(c => c.default || c),
  CommonQuantityNumericInput: () => import('../..\\components\\common\\quantity-numeric-input.vue' /* webpackChunkName: "components/common-quantity-numeric-input" */).then(c => c.default || c),
  CommonRegisterCard: () => import('../..\\components\\common\\register-card.vue' /* webpackChunkName: "components/common-register-card" */).then(c => c.default || c),
  CommonScanItemDialog: () => import('../..\\components\\common\\scan-item-dialog.vue' /* webpackChunkName: "components/common-scan-item-dialog" */).then(c => c.default || c),
  CommonScrollTable: () => import('../..\\components\\common\\scroll-table.vue' /* webpackChunkName: "components/common-scroll-table" */).then(c => c.default || c),
  CommonSelectStoreDialog: () => import('../..\\components\\common\\select-store-dialog.vue' /* webpackChunkName: "components/common-select-store-dialog" */).then(c => c.default || c),
  CommonSwitchUser: () => import('../..\\components\\common\\switch-user.vue' /* webpackChunkName: "components/common-switch-user" */).then(c => c.default || c),
  CustomOrderDetailComponent: () => import('../..\\components\\custom-order\\custom-order-detail-component.vue' /* webpackChunkName: "components/custom-order-detail-component" */).then(c => c.default || c),
  CustomOrderDetailDialog: () => import('../..\\components\\custom-order\\custom-order-detail-dialog.vue' /* webpackChunkName: "components/custom-order-detail-dialog" */).then(c => c.default || c),
  CustomOrderDetail: () => import('../..\\components\\custom-order\\custom-order-detail.vue' /* webpackChunkName: "components/custom-order-detail" */).then(c => c.default || c),
  CustomOrderTable: () => import('../..\\components\\custom-order\\custom-order-table.vue' /* webpackChunkName: "components/custom-order-table" */).then(c => c.default || c),
  CustomOrderEmailPreviewDialog: () => import('../..\\components\\custom-order\\email-preview-dialog.vue' /* webpackChunkName: "components/custom-order-email-preview-dialog" */).then(c => c.default || c),
  CustomerCreditsAddInvoiceDialog: () => import('../..\\components\\customer-credits\\add-invoice-dialog.vue' /* webpackChunkName: "components/customer-credits-add-invoice-dialog" */).then(c => c.default || c),
  CustomerCreditsExpandedTable: () => import('../..\\components\\customer-credits\\expanded-table.vue' /* webpackChunkName: "components/customer-credits-expanded-table" */).then(c => c.default || c),
  CustomerCreditsInvoiceItemDetailDialog: () => import('../..\\components\\customer-credits\\invoice-item-detail-dialog.vue' /* webpackChunkName: "components/customer-credits-invoice-item-detail-dialog" */).then(c => c.default || c),
  CustomerCreditsSubmitCard: () => import('../..\\components\\customer-credits\\submit-card.vue' /* webpackChunkName: "components/customer-credits-submit-card" */).then(c => c.default || c),
  CustomerCreditsYourMemoDialog: () => import('../..\\components\\customer-credits\\your-memo-dialog.vue' /* webpackChunkName: "components/customer-credits-your-memo-dialog" */).then(c => c.default || c),
  DashboardSummaryTable: () => import('../..\\components\\dashboard\\dashboard-summary-table.vue' /* webpackChunkName: "components/dashboard-summary-table" */).then(c => c.default || c),
  DashboardTable: () => import('../..\\components\\dashboard\\dashboard-table.vue' /* webpackChunkName: "components/dashboard-table" */).then(c => c.default || c),
  DashboardDayPicker: () => import('../..\\components\\dashboard\\day-picker.vue' /* webpackChunkName: "components/dashboard-day-picker" */).then(c => c.default || c),
  DashboardFilterToggleButtons: () => import('../..\\components\\dashboard\\filter-toggle-buttons.vue' /* webpackChunkName: "components/dashboard-filter-toggle-buttons" */).then(c => c.default || c),
  DashboardGraphCard: () => import('../..\\components\\dashboard\\graph-card.vue' /* webpackChunkName: "components/dashboard-graph-card" */).then(c => c.default || c),
  DashboardMonthPicker: () => import('../..\\components\\dashboard\\month-picker.vue' /* webpackChunkName: "components/dashboard-month-picker" */).then(c => c.default || c),
  DashboardTableCard: () => import('../..\\components\\dashboard\\table-card.vue' /* webpackChunkName: "components/dashboard-table-card" */).then(c => c.default || c),
  IntegrationsSquareConfiguration: () => import('../..\\components\\integrations\\square-configuration.vue' /* webpackChunkName: "components/integrations-square-configuration" */).then(c => c.default || c),
  IntegrationsSquareConnectTerminal: () => import('../..\\components\\integrations\\square-connect-terminal.vue' /* webpackChunkName: "components/integrations-square-connect-terminal" */).then(c => c.default || c),
  IntegrationsSquareNamingTerminal: () => import('../..\\components\\integrations\\square-naming-terminal.vue' /* webpackChunkName: "components/integrations-square-naming-terminal" */).then(c => c.default || c),
  IntegrationsSquareTerminals: () => import('../..\\components\\integrations\\square-terminals.vue' /* webpackChunkName: "components/integrations-square-terminals" */).then(c => c.default || c),
  LayawayCard: () => import('../..\\components\\layaway\\layaway-card.vue' /* webpackChunkName: "components/layaway-card" */).then(c => c.default || c),
  LayawayDetailDialog: () => import('../..\\components\\layaway\\layaway-detail-dialog.vue' /* webpackChunkName: "components/layaway-detail-dialog" */).then(c => c.default || c),
  LayawayPaymentsDialog: () => import('../..\\components\\layaway\\layaway-payments-dialog.vue' /* webpackChunkName: "components/layaway-payments-dialog" */).then(c => c.default || c),
  NewSaleAddCustomerEvent: () => import('../..\\components\\new-sale\\add-customer-event.vue' /* webpackChunkName: "components/new-sale-add-customer-event" */).then(c => c.default || c),
  NewSaleAddCustomerShippingDialog: () => import('../..\\components\\new-sale\\add-customer-shipping-dialog.vue' /* webpackChunkName: "components/new-sale-add-customer-shipping-dialog" */).then(c => c.default || c),
  NewSaleAddEditCustomerDialog: () => import('../..\\components\\new-sale\\add-edit-customer-dialog.vue' /* webpackChunkName: "components/new-sale-add-edit-customer-dialog" */).then(c => c.default || c),
  NewSaleCardPaymentDialog: () => import('../..\\components\\new-sale\\card-payment-dialog.vue' /* webpackChunkName: "components/new-sale-card-payment-dialog" */).then(c => c.default || c),
  NewSaleCartItemDetailDialog: () => import('../..\\components\\new-sale\\cart-item-detail-dialog.vue' /* webpackChunkName: "components/new-sale-cart-item-detail-dialog" */).then(c => c.default || c),
  NewSaleCheckoutDialog: () => import('../..\\components\\new-sale\\checkout-dialog.vue' /* webpackChunkName: "components/new-sale-checkout-dialog" */).then(c => c.default || c),
  NewSaleCustomerDetailDialog: () => import('../..\\components\\new-sale\\customer-detail-dialog.vue' /* webpackChunkName: "components/new-sale-customer-detail-dialog" */).then(c => c.default || c),
  NewSaleCustomerInfoDialog: () => import('../..\\components\\new-sale\\customer-info-dialog.vue' /* webpackChunkName: "components/new-sale-customer-info-dialog" */).then(c => c.default || c),
  NewSaleCustomerNoteDialog: () => import('../..\\components\\new-sale\\customer-note-dialog.vue' /* webpackChunkName: "components/new-sale-customer-note-dialog" */).then(c => c.default || c),
  NewSaleCustomerOrderHistory: () => import('../..\\components\\new-sale\\customer-order-history.vue' /* webpackChunkName: "components/new-sale-customer-order-history" */).then(c => c.default || c),
  NewSaleItemsToBuy: () => import('../..\\components\\new-sale\\items-to-buy.vue' /* webpackChunkName: "components/new-sale-items-to-buy" */).then(c => c.default || c),
  NewSalePaymentCard: () => import('../..\\components\\new-sale\\payment-card.vue' /* webpackChunkName: "components/new-sale-payment-card" */).then(c => c.default || c),
  NewSaleSelectCustomerDialog: () => import('../..\\components\\new-sale\\select-customer-dialog.vue' /* webpackChunkName: "components/new-sale-select-customer-dialog" */).then(c => c.default || c),
  NewSaleSummaryCalculations: () => import('../..\\components\\new-sale\\summary-calculations.vue' /* webpackChunkName: "components/new-sale-summary-calculations" */).then(c => c.default || c),
  NewSaleSummaryCard: () => import('../..\\components\\new-sale\\summary-card.vue' /* webpackChunkName: "components/new-sale-summary-card" */).then(c => c.default || c),
  NewSaleSummaryRepairCard: () => import('../..\\components\\new-sale\\summary-repair-card.vue' /* webpackChunkName: "components/new-sale-summary-repair-card" */).then(c => c.default || c),
  OrdersOrderCard: () => import('../..\\components\\orders\\order-card.vue' /* webpackChunkName: "components/orders-order-card" */).then(c => c.default || c),
  OrdersOrderDetailDialog: () => import('../..\\components\\orders\\order-detail-dialog.vue' /* webpackChunkName: "components/orders-order-detail-dialog" */).then(c => c.default || c),
  OrdersOrderImagesDialog: () => import('../..\\components\\orders\\order-images-dialog.vue' /* webpackChunkName: "components/orders-order-images-dialog" */).then(c => c.default || c),
  OrdersOrderNoteDialog: () => import('../..\\components\\orders\\order-note-dialog.vue' /* webpackChunkName: "components/orders-order-note-dialog" */).then(c => c.default || c),
  RegisterAdjustmentDialog: () => import('../..\\components\\register\\adjustment-dialog.vue' /* webpackChunkName: "components/register-adjustment-dialog" */).then(c => c.default || c),
  RegisterBalanceCard: () => import('../..\\components\\register\\balance-card.vue' /* webpackChunkName: "components/register-balance-card" */).then(c => c.default || c),
  RegisterFilterCardDatePicker: () => import('../..\\components\\register\\filter-card-date-picker.vue' /* webpackChunkName: "components/register-filter-card-date-picker" */).then(c => c.default || c),
  RegisterOpenCloseRegisterDialog: () => import('../..\\components\\register\\open-close-register-dialog.vue' /* webpackChunkName: "components/register-open-close-register-dialog" */).then(c => c.default || c),
  RegisterPrintDailyDialog: () => import('../..\\components\\register\\print-daily-dialog.vue' /* webpackChunkName: "components/register-print-daily-dialog" */).then(c => c.default || c),
  RegisterIconButton: () => import('../..\\components\\register\\register-icon-button.vue' /* webpackChunkName: "components/register-icon-button" */).then(c => c.default || c),
  RepairCenterTable: () => import('../..\\components\\repair-center\\repair-center-table.vue' /* webpackChunkName: "components/repair-center-table" */).then(c => c.default || c),
  RepairCheckoutDialog: () => import('../..\\components\\repair-center\\repair-checkout-dialog.vue' /* webpackChunkName: "components/repair-checkout-dialog" */).then(c => c.default || c),
  RepairDetailDialog: () => import('../..\\components\\repair-center\\repair-detail-dialog.vue' /* webpackChunkName: "components/repair-detail-dialog" */).then(c => c.default || c),
  RepairItemImagesDialog: () => import('../..\\components\\repair-center\\repair-item-images-dialog.vue' /* webpackChunkName: "components/repair-item-images-dialog" */).then(c => c.default || c),
  RepairCenterSummaryRepairCalculations: () => import('../..\\components\\repair-center\\summary-repair-calculations.vue' /* webpackChunkName: "components/repair-center-summary-repair-calculations" */).then(c => c.default || c),
  RepairCenterTicketDetails: () => import('../..\\components\\repair-center\\ticket-details.vue' /* webpackChunkName: "components/repair-center-ticket-details" */).then(c => c.default || c),
  SharedAddComponentsDialog: () => import('../..\\components\\shared\\add-components-dialog.vue' /* webpackChunkName: "components/shared-add-components-dialog" */).then(c => c.default || c),
  SharedAttributeListGrid: () => import('../..\\components\\shared\\attribute-list-grid.vue' /* webpackChunkName: "components/shared-attribute-list-grid" */).then(c => c.default || c),
  SharedCloseBtn: () => import('../..\\components\\shared\\close-btn.vue' /* webpackChunkName: "components/shared-close-btn" */).then(c => c.default || c),
  SharedComponentGroupDialog: () => import('../..\\components\\shared\\component-group-dialog.vue' /* webpackChunkName: "components/shared-component-group-dialog" */).then(c => c.default || c),
  SharedComponentsCard: () => import('../..\\components\\shared\\components-card.vue' /* webpackChunkName: "components/shared-components-card" */).then(c => c.default || c),
  SharedCustomButton: () => import('../..\\components\\shared\\custom-button.vue' /* webpackChunkName: "components/shared-custom-button" */).then(c => c.default || c),
  SharedCustomCombo: () => import('../..\\components\\shared\\custom-combo.vue' /* webpackChunkName: "components/shared-custom-combo" */).then(c => c.default || c),
  SharedDarkButton: () => import('../..\\components\\shared\\dark-button.vue' /* webpackChunkName: "components/shared-dark-button" */).then(c => c.default || c),
  SharedDynamicSummaryCard: () => import('../..\\components\\shared\\dynamic-summary-card.vue' /* webpackChunkName: "components/shared-dynamic-summary-card" */).then(c => c.default || c),
  SharedFileUploader: () => import('../..\\components\\shared\\file-uploader.vue' /* webpackChunkName: "components/shared-file-uploader" */).then(c => c.default || c),
  SharedFilterAutocompleteSelection: () => import('../..\\components\\shared\\filter-autocomplete-selection.vue' /* webpackChunkName: "components/shared-filter-autocomplete-selection" */).then(c => c.default || c),
  SharedFilterCardElement: () => import('../..\\components\\shared\\filter-card-element.vue' /* webpackChunkName: "components/shared-filter-card-element" */).then(c => c.default || c),
  SharedFilterCard: () => import('../..\\components\\shared\\filter-card.vue' /* webpackChunkName: "components/shared-filter-card" */).then(c => c.default || c),
  SharedImageCropper: () => import('../..\\components\\shared\\image-cropper.vue' /* webpackChunkName: "components/shared-image-cropper" */).then(c => c.default || c),
  SharedImageUploaderField: () => import('../..\\components\\shared\\image-uploader-field.vue' /* webpackChunkName: "components/shared-image-uploader-field" */).then(c => c.default || c),
  SharedImageUploader: () => import('../..\\components\\shared\\image-uploader.vue' /* webpackChunkName: "components/shared-image-uploader" */).then(c => c.default || c),
  SharedLabeledControlFields: () => import('../..\\components\\shared\\labeled-control-fields.vue' /* webpackChunkName: "components/shared-labeled-control-fields" */).then(c => c.default || c),
  SharedLabeledControlLayout: () => import('../..\\components\\shared\\labeled-control-layout.vue' /* webpackChunkName: "components/shared-labeled-control-layout" */).then(c => c.default || c),
  SharedNoteCardLayout: () => import('../..\\components\\shared\\note-card-layout.vue' /* webpackChunkName: "components/shared-note-card-layout" */).then(c => c.default || c),
  SharedPrimaryButton: () => import('../..\\components\\shared\\primary-button.vue' /* webpackChunkName: "components/shared-primary-button" */).then(c => c.default || c),
  SharedPrimaryTitle: () => import('../..\\components\\shared\\primary-title.vue' /* webpackChunkName: "components/shared-primary-title" */).then(c => c.default || c),
  SharedRefundDialog: () => import('../..\\components\\shared\\refund-dialog.vue' /* webpackChunkName: "components/shared-refund-dialog" */).then(c => c.default || c),
  SharedSecondaryButton: () => import('../..\\components\\shared\\secondary-button.vue' /* webpackChunkName: "components/shared-secondary-button" */).then(c => c.default || c),
  SharedSimpleActionButton: () => import('../..\\components\\shared\\simple-action-button.vue' /* webpackChunkName: "components/shared-simple-action-button" */).then(c => c.default || c),
  SharedSquareCheckoutDialog: () => import('../..\\components\\shared\\square-checkout-dialog.vue' /* webpackChunkName: "components/shared-square-checkout-dialog" */).then(c => c.default || c),
  SharedSquareSelectTerminalDialog: () => import('../..\\components\\shared\\square-select-terminal-dialog.vue' /* webpackChunkName: "components/shared-square-select-terminal-dialog" */).then(c => c.default || c),
  SharedSummaryScrollTable: () => import('../..\\components\\shared\\summary-scroll-table.vue' /* webpackChunkName: "components/shared-summary-scroll-table" */).then(c => c.default || c),
  SharedSvgIconButton: () => import('../..\\components\\shared\\svg-icon-button.vue' /* webpackChunkName: "components/shared-svg-icon-button" */).then(c => c.default || c),
  SharedTreeListRow: () => import('../..\\components\\shared\\tree-list-row.vue' /* webpackChunkName: "components/shared-tree-list-row" */).then(c => c.default || c),
  SharedUploadFile: () => import('../..\\components\\shared\\upload-file.vue' /* webpackChunkName: "components/shared-upload-file" */).then(c => c.default || c),
  CommonMyProfileEditFormCard: () => import('../..\\components\\common\\my-profile\\edit-form-card.vue' /* webpackChunkName: "components/common-my-profile-edit-form-card" */).then(c => c.default || c),
  CommonMyProfileGeneralInfoCard: () => import('../..\\components\\common\\my-profile\\general-info-card.vue' /* webpackChunkName: "components/common-my-profile-general-info-card" */).then(c => c.default || c),
  CommonMyProfilePasswordChangeCard: () => import('../..\\components\\common\\my-profile\\password-change-card.vue' /* webpackChunkName: "components/common-my-profile-password-change-card" */).then(c => c.default || c),
  CommonOrderDetailCard: () => import('../..\\components\\common\\order\\detail-card.vue' /* webpackChunkName: "components/common-order-detail-card" */).then(c => c.default || c),
  NewSaleCustomerEventsTab: () => import('../..\\components\\new-sale\\customer-detail-tabs\\customer-events-tab.vue' /* webpackChunkName: "components/new-sale-customer-events-tab" */).then(c => c.default || c),
  NewSaleCustomerMainInfoTab: () => import('../..\\components\\new-sale\\customer-detail-tabs\\customer-main-info-tab.vue' /* webpackChunkName: "components/new-sale-customer-main-info-tab" */).then(c => c.default || c),
  NewSaleCustomerShippingInfoTab: () => import('../..\\components\\new-sale\\customer-detail-tabs\\customer-shipping-info-tab.vue' /* webpackChunkName: "components/new-sale-customer-shipping-info-tab" */).then(c => c.default || c),
  ReportsSaleReportsCustomerSaleDialog: () => import('../..\\components\\reports\\sale-reports\\customer-sale-dialog.vue' /* webpackChunkName: "components/reports-sale-reports-customer-sale-dialog" */).then(c => c.default || c),
  ReportsSaleReportsItemGroupDetailDialog: () => import('../..\\components\\reports\\sale-reports\\item-group-detail-dialog.vue' /* webpackChunkName: "components/reports-sale-reports-item-group-detail-dialog" */).then(c => c.default || c),
  ReportsSaleReportsSalesByItems: () => import('../..\\components\\reports\\sale-reports\\sales-by-items.vue' /* webpackChunkName: "components/reports-sale-reports-sales-by-items" */).then(c => c.default || c),
  ReportsSaleReportsSalesDetails: () => import('../..\\components\\reports\\sale-reports\\sales-details.vue' /* webpackChunkName: "components/reports-sale-reports-sales-details" */).then(c => c.default || c),
  ReportsSaleReportsSalesHistory: () => import('../..\\components\\reports\\sale-reports\\sales-history.vue' /* webpackChunkName: "components/reports-sale-reports-sales-history" */).then(c => c.default || c),
  ReportsStoreTransactions: () => import('../..\\components\\reports\\store-transactions\\store-transactions.vue' /* webpackChunkName: "components/reports-store-transactions" */).then(c => c.default || c)
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
